import {
  getExchangeBankRatesList,
} from "../../redux/actions/memberAction";
import React, { useEffect, useReducer, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Button, Col, Heading, Row, Card, Table } from "../../components";
import Content from "../../layouts/content";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Helmet } from "react-helmet";
import { List } from "@material-ui/icons";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { DataGrid } from "@mui/x-data-grid";
import Nav from "../../layouts/nav/Nav"
import { Container, useStylesButton, useStylesHeader, useStyles} from '../../shared/styles/commonStyles';

const CentralBankRates: React.FC<{}> = ({ }) => {
  const classesHeader = useStylesHeader();
  const classesName = useStylesButton();
  const classesButton = useStylesButton();
  const classesStyle = useStyles();

  const dis = useDispatch();
  type State = {
    dateStart: Date | null;
    dateEnd: Date | null;
    loading: boolean;
  };

  const initialState: State = {
    dateStart: null,
    dateEnd: null,
    loading: false,
  };

  type Action =
    | { type: "setDateStart"; payload: Date }
    | { type: "setDateEnd"; payload: Date }
    | { type: "setLoading"; payload: boolean };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "setDateStart":
        return {
          ...state,
          dateStart: action.payload,
        };
      case "setDateEnd":
        return {
          ...state,
          dateEnd: action.payload,
        };
      case "setLoading":
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { message, exchangeBankRateList = [] } = useSelector(
    (state: RootStateOrAny) => state.exchangeBankRateList
  );
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openDateWarning, setOpenDateWarning] = React.useState(false);

  const [startDateEmptyError, setStartDateEmptyError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);

  const [endDateEmptyError, setEndDateEmptyError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const getExchangeBankRate = () => {
    dis(getExchangeBankRatesList({ state }));
  }

  const handleStartDate = (event: any) => {
    if (event != null) {
      setStartDateEmptyError(false);
      dispatch({
        type: "setDateStart",
        payload: new Date(event),
      });
    }
    else if (event == "") {
      setStartDateError(true)
      dispatch({
        type: "setDateStart",
        payload: new Date(""),
      });
    } else {
      setStartDateEmptyError(true);
      dispatch({
        type: "setDateStart",
        payload: new Date(""),
      });
    }
  };

  const handleEndDate = (event: any) => {
    if (event != null) {
      setEndDateEmptyError(false);
      dispatch({
        type: "setDateEnd",
        payload: new Date(event),
      });
    }
    else if (event == "") {
      setEndDateError(true)
      dispatch({
        type: "setDateEnd",
        payload: new Date(""),
      });
    } else {
      setEndDateEmptyError(true);
      dispatch({
        type: "setDateEnd",
        payload: new Date(""),
      });
    }

  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarning(false);
    setOpenDateWarning(false);
  };

  var dd = state.dateStart?.getDate();
  var mm = Number(state.dateStart?.getMonth()) + 1;
  var yyyy = state.dateStart?.getFullYear();
  var datestart = mm + "/" + dd + "/" + yyyy + " " + "5:30:00";
  var sdate = new Date(datestart);

  var pDate = state.dateEnd?.toLocaleDateString();
  var dd = state.dateEnd?.getDate();
  var mm = Number(state.dateEnd?.getMonth()) + 1;
  var yyyy = state.dateEnd?.getFullYear();
  var dateend = mm + "/" + dd + "/" + yyyy + " " + "22:30:00";
  var edate = new Date(dateend);

  if (
    state.dateStart != null &&
    state.dateEnd != null &&
    state.dateStart.getFullYear() != 1970 &&
    state.dateEnd.getFullYear() != 1970
  ) {
    state.dateStart = sdate;
    state.dateEnd = edate;
  }
  const getExchangeBankRates = () => {
    let valid = true;
    if (state.dateStart == null || state.dateStart == new Date("")) {
      setStartDateEmptyError(true);
      valid = false;
    }
    if (state.dateEnd == null) {
      setEndDateEmptyError(true);
      valid = false;
    }
    if (state.dateStart != null && state.dateEnd != null
      && state.dateStart.getFullYear() == 1970 &&
      state.dateEnd.getFullYear() == 1970) {
      valid = false;
    }

    if (valid) {
      if (state.dateStart != null && state.dateEnd != null
        && state.dateEnd > state.dateStart) {
        dispatch({
          type: "setLoading",
          payload: true,
        });
        getExchangeBankRate();
      } else {
        setOpenDateWarning(true);
      }
    } else {
      setOpenWarning(true);
    }
  };

  useEffect(() => {
    dispatch({
      type: "setLoading",
      payload: false,
    });
  }, [message, exchangeBankRateList]);

  //date değişikliklerinin anlık olarak birbirine etki etmesi için yazıldı
  useEffect(() => {
    if (state.dateStart && state.dateEnd) {
      if (state.dateStart <= state.dateEnd) {
        setStartDateError(false);
        setEndDateError(false);
      }
      if (state.dateStart && isNaN(state.dateEnd.getTime())) {
        setStartDateError(false);
      }
      if (state.dateEnd && isNaN(state.dateStart.getTime())) {
        setEndDateError(false);
      }
    }
    if (state.dateStart && !state.dateEnd) {
      setStartDateError(false);
    }
    if (state.dateEnd && !state.dateStart) {
      setEndDateError(false);
    }
  }, [state.dateStart, state.dateEnd])


  return (
    <>
      <Helmet>
        <title>RDC Board - Merkez Bankası Kurları</title>
      </Helmet>
      <div className={classesStyle.scroll}>
        <Nav />
        <div className={classesName.colorBackground}>
          <Content>
            <Heading as="h5" mb="5px" className={classesHeader.header}>
              Merkez Bankası Kurları
            </Heading>
            <Container className={classesName.TextFieldControl}>
              <Row>
                <Col lg={3} md sm xs mb={25}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Başlangıç Tarihi *"
                      value={state.dateStart}
                      onChange={(newValue: any) => {
                        const formattedDate = newValue ? newValue.toLocaleDateString('tr-TR') : '';
                        if (formattedDate.length === 10) {
                          if (!state.dateEnd || newValue <= state.dateEnd) {
                            setStartDateError(false);
                            handleStartDate(newValue);
                          }
                          else {
                            setStartDateError(true);
                            handleStartDate(newValue);
                          }
                        } else if (formattedDate.length === 0) {
                          handleStartDate(null)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={startDateEmptyError || startDateError}
                          helperText={
                            startDateEmptyError
                              ? 'Bu alan boş bırakılamaz'
                              : (startDateError && state.dateEnd)
                                ? 'Başlangıç tarihi bitiş tarihinden daha sonraki bir tarihi belirtemez.'
                                : ''
                          } />
                      )}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Col>
                <Col lg={3} md sm xs mb={25}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Bitiş Tarihi *"
                      value={state.dateEnd}
                      onChange={(newValue: any) => {
                        const formattedDate = newValue ? newValue.toLocaleDateString('tr-TR') : '';
                        if (formattedDate.length === 10) {
                          if (!state.dateStart || newValue >= state.dateStart) {
                            setEndDateError(false);
                            handleEndDate(newValue);
                          } else {
                            setEndDateError(true);
                            handleEndDate(newValue);
                          }
                        } else if (formattedDate.length === 0) {
                          handleEndDate(null)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={endDateEmptyError || endDateError}
                          helperText={
                            endDateEmptyError
                              ? 'Bu alan boş bırakılamaz'
                              : (endDateError && state.dateStart)
                                ? 'Bitiş tarihi başlangıç tarihinden daha önceki bir tarihi belirtemez.'
                                : ''
                          }
                        />
                      )}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button
                    iconButton
                    size="lg"
                    color="light"
                    className={classesButton.buttonList}
                    onClick={getExchangeBankRates}
                  >
                    <List
                      width={18}
                      height={18}
                      className={classesButton.buttonIcon}
                    />
                    LİSTELE
                  </Button>
                  <Snackbar
                    open={openWarning}
                    autoHideDuration={1000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={() => handleClose}
                      severity="warning"
                      sx={{ width: "100%" }}
                    >
                      Tarih Seçeneğini boş geçilemez.
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={openDateWarning}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={() => handleClose}
                      severity="warning"
                      sx={{ width: "100%" }}
                    >
                      Başlangıç tarihi bitiş tarihinden daha sonraki bir tarihi belirtemez.
                    </Alert>
                  </Snackbar>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={state.loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Col>
              </Row>
              <br />
              <br />
              {Object.values(exchangeBankRateList).length > 0 && (
                <Card>
                  <Table bordered marginBottom={"0px"}>
                    <thead>
                      <tr>
                        <th style={{ width: "66px", paddingLeft: "20px", fontSize: 12 }}>
                          {/* Kolon 1 */}
                        </th>
                        <th style={{ width: "100px", paddingLeft: "20px", fontSize: 15 }}>
                          DOLAR
                        </th>
                        <th style={{ width: "100px", paddingLeft: "20px", fontSize: 15 }}>
                          EURO
                        </th>
                        <th style={{ width: "100px", paddingLeft: "20px", fontSize: 15 }}>
                          STERLİN
                        </th>
                      </tr>
                    </thead>
                  </Table>

                  <DataGrid
                    sx={{ fontSize: "13px", overflowX: "scroll" }}
                    autoHeight={true}
                    rowHeight={50}
                    localeText={{
                      noRowsLabel: 'Kayıt bulunamadı'
                    }}
                    rows={Object.values(exchangeBankRateList.data).map(
                      (item: any, index: any) => ({
                        id: index,
                        dateTime: item.DateTime ?? "",
                        exchangeRateforDolar: item.ExchangeRateforDolar,
                        exchangeRateforEuro: item.ExchangeRateforEuro,
                        exchangeRateforSterlin: item.ExchangeRateforSterlin,
                        exchangeRateforDolarBuy: item.ExchangeRateforDolarBuy,
                        exchangeRateforEuroBuy: item.ExchangeRateforEuroBuy,
                        exchangeRateforSterlinBuy: item.ExchangeRateforSterlinBuy,
                      })
                    )}
                    columns={[
                      {
                        field: "id",
                        headerName: "ID",
                        hide: true,
                      },
                      {
                        field: "dateTime",
                        headerName: "Tarih",
                        flex: 3,
                      },
                      {
                        field: "exchangeRateforDolarBuy",
                        headerName: "ALIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforDolar",
                        headerName: "SATIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforEuroBuy",
                        headerName: "ALIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforEuro",
                        headerName: "SATIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforSterlinBuy",
                        headerName: "ALIŞ",
                        flex: 2,
                      },
                      {
                        field: "exchangeRateforSterlin",
                        headerName: "SATIŞ",
                        flex: 2,
                      },
                    ]}
                  /> 
                </Card>
              )}
              <br />
            </Container>
          </Content>
        </div>
      </div>
    </>
  );
};
export default CentralBankRates;
