import React, { lazy, useEffect } from "react";
import {
  Edit3,
  User,
  HelpCircle,
  LifeBuoy,
  Settings,
  LogOut,
  AtSign,
} from "react-feather";
import { DropdownToggle, Dropdown } from "../../ui/dropdown/dropdown";
import { Avatar, AvatarInitial } from "../../ui/avatar/avatar";
import {
  StyledDropMenu,
  StyledAuthorName,
  StyledAuthorRole,
  StyledDropItem,
  StyledDivider,
  StyledAvatar,
  StyledUserName,
  StyledName,
} from "./style";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { getMyUserDetailList } from "../../../../redux/actions/memberAction";
import { useReducer } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { BroadcastChannel } from "broadcast-channel";
import { teknolink } from "../../../../appconfig";
import { Button } from "../../../../components";
import { useStylesButton } from "../../../../shared/styles/commonStyles";
import { StyledLink } from "../../ui/anchor/style";

const roles = localStorage.getItem("roles");

const Help = lazy(() => import("../../../../pages/help/Help"));
// "./pages/help/Help"

const logoutChannel = new BroadcastChannel("logout");

export const logOut = () => {
  logoutChannel.postMessage("Logout");
  localStorage.removeItem("token");
  window.location.href = window.location.origin + "/login";
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logOut();
    logoutChannel.close();
  };
};

const ProfileDropdown: React.FC = () => {
  const classesButton = useStylesButton();
  const dis = useDispatch();
  type State = {
    id: number;
  };

  const initialState: State = {
    id: 0,
  };

  type Action = { type: "id"; payload: number };
  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "id":
        return {
          ...state,
          id: action.payload,
        };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  function getMyUserDetails() {
    dis(getMyUserDetailList({ state }));
  }
  const { myUserDetailList } = useSelector(
    (state: RootStateOrAny) => state.myUserDetail
  );

  useEffect(() => {
    getMyUserDetails();
  }, []);

  console.log(myUserDetailList);
  return (
    <Dropdown direction="down" className="dropdown-profile">
      <DropdownToggle variant="texted">
        <StyledAvatar size="sm">
          <AvatarInitial>
            {Object.values(myUserDetailList).map((myUserItem: any, index) => (
              <StyledUserName key={index}>{myUserItem?.avatar}</StyledUserName>
            ))}
          </AvatarInitial>
        </StyledAvatar>
      </DropdownToggle>
      <StyledDropMenu>
        <StyledLink to={"/profile"}>
          <Avatar size="lg">
            <AvatarInitial>
              {Object.values(myUserDetailList).map((myUserItem: any, index) => (
                <StyledUserName key={index}>{myUserItem.avatar}</StyledUserName>
              ))}
            </AvatarInitial>
          </Avatar>
          <StyledAuthorName>
            {Object.values(myUserDetailList).map((myUserItem: any, index) => (
              <StyledName key={index}>{myUserItem.nameSurname}</StyledName>
            ))}
            {Object.values(myUserDetailList).length > 0 &&
              Object.values(myUserDetailList).map(
                (myUserItem: any) =>
                  myUserItem.DepartmentList &&
                  Object.values(myUserItem.DepartmentList).length > 0 &&
                  Object.values(myUserItem.DepartmentList).map(
                    (department: any, index) => (
                      <StyledAuthorRole key={index}>
                        {" "}
                        {department.Task}
                      </StyledAuthorRole>
                    )
                  )
              )}
          </StyledAuthorName>
        </StyledLink>

        <StyledDropItem path="/profile">
          <Edit3 size="24" />
          Profili incele
        </StyledDropItem>
        <StyledDivider />
        <StyledDropItem path="/help" mt="10px">
          <HelpCircle size="24" />
          Yardım
        </StyledDropItem>
        <StyledDropItem path="/profile" mt="10px">
          <Settings size="24" />
          Hesap ayarları
        </StyledDropItem>
        <StyledDropItem path="/profile" mt="10px">
          <Settings size="24" />
          Gizlilik ayarları
        </StyledDropItem>
        {roles?.includes("Technopark") && (
          <StyledDropItem path={teknolink} mt="10px">
            <AtSign size="24" />
            TeknoPark
          </StyledDropItem>
        )}
        <StyledDropItem path="/login" mt="10px">
          <Button
            iconButton
            size="lg"
            color="white"
            className={classesButton.buttonList}
            onClick={logOut}
          >
            <ExitToAppIcon
              width={18}
              height={18}
              className={classesButton.buttonIcon}
            />
            Çıkış Yap
          </Button>
        </StyledDropItem>
      </StyledDropMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
