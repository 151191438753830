import React from 'react';
import { useState, useEffect, useReducer } from "react";
import { Heading } from "../../components/index";
import Content from "../../layouts/content";
import { Container, useStylesButton, useStylesHeader, useStyles } from '../../shared/styles/commonStyles';
import { Helmet } from 'react-helmet';



const ContractTracking: React.FC = () => {


const classesHeader = useStylesHeader();
const classesName = useStylesButton();
const classesStyle = useStyles();

const Nav = React.lazy(() => import("../../layouts/nav/Nav"));

return (
    <>
        <Helmet>
            <title>RDC Board - Sözleşme Takip</title>
        </Helmet>
        <div className={classesStyle.scroll}>
            <React.Suspense fallback={<div>Loading...</div>}>
                <Nav />
            </React.Suspense>
            <div className={classesName.colorBackground}>
                <Content>
                    <Heading as="h5" mb="5px" className={classesHeader.header}>
                        Sözleşme Takip
                    </Heading>
                </Content>
            </div>
        </div>
    </>
);
};

export default ContractTracking;