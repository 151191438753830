import React from "react";
import { Heart } from "react-feather";
import {
    StyledFooter,
    StyledFooterLeft,
    StyledFooterRight,
    StyledFooterNav,
    StyledFotterNavLink,
} from "./style";

const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <StyledFooterLeft>
                <span>&copy; RDC Partner {new Date().getFullYear()} </span>
                <span className="copright-link">
                    MADE WITH <Heart size="24" /> BY{"  "}
                    <a
                        href="/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        RDC Partner
                    </a>
                </span>
            </StyledFooterLeft>
        </StyledFooter>
    );
};

export default Footer;
