import React from "react";
import Layout from "../../layouts";
import Content from "../../layouts/content";
import { StyledDesc, StyledImg, StyledSubTitle, StyledTitle, StyledWrap } from "./style";
import image from "../../static/error404.png";

const NotFound: React.FC = () => {
    return (
        <Layout>
            <Content>
                <StyledWrap>
                    <StyledImg>
                        <img src={image} alt="404" />
                    </StyledImg>
                    <StyledTitle>404 Sayfa Bulunamadı</StyledTitle>
                    <StyledDesc>
                    </StyledDesc>
                </StyledWrap>
            </Content>
        </Layout>
    );

}
export default NotFound;