import styled, {
    device,
    themeGet,
    layout,
    space,
    css,
    tinycolor,
} from "../../shared/styled";
import { Button } from "../../components/src/ui/button/Button";

export const StyledHeader = styled.header`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    align-items: stretch;
    height: 55px;
    padding: 0;
    justify-content: flex-start;
    background-color: #fff;
    border-bottom: 1px solid #485e9029;
    ${device.large} {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    text-decoration: none;
    
    .dark-mode & {
            background-color:rgb(28, 39, 60) !important;
        svg {
            color: #ffffff !important;
        }
        &:hover {
            color: #4a90e2 !important;
            svg {
                color: #4a90e2 !important;
            }
        }
    }
`;

export const StyledLogo = styled.div`
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-right: 0;
    order: 1;
    ${device.large} {
        width: 240px;
        padding: 0 0 0 20px;
    }
`;

interface INavbar {
    $isOpen?: boolean;
}

export const StyledNavbarWrap = styled.div<INavbar>`
    order: 3;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 21, 32, 0.7);
    overflow: hidden;
    z-index: 1060;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    will-change: visibility, opacity;
    ${({ $isOpen }) =>
        $isOpen === true &&
        css`
            visibility: visible;
            opacity: 1;
        `}
    ${device.xlarge} {
        padding: 0 30px;
    }
    ${device.large} {
        width: auto;
        padding: 0;
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        overflow: visible;
        display: flex;
        justify-content: center;
        order: 2;
        transform: none;
        z-index: auto;
        flex: 1;
        visibility: visible;
        opacity: 1;
        background: transparent;
    }
`;

export const StyledNavbarMenu = styled.nav<INavbar>`
    width: 260px;
    height: 100%;
    background-color: #fff;
    transition: all 0.4s;
    will-change: transform;
    transform: translateX(-260px);
    z-index: 99999;
    position: relative;
    ${({ $isOpen }) =>
        $isOpen === true &&
        css`
            transform: translateX(0);
        `}
    ${device.large} {
        background-color: transparent;
        width: 100%;
        max-width: 780px;
        margin-top: -3px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        transform: translateX(0);
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: #1c273c";
            ${device.large} {
                background-color: transparent;
            }
        `}
`;

export const StyledNavbarHeader = styled.div`
    height: 55px;
    padding: 0 15px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #485e9029;
    ${device.large} {
        display: none;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: "#1c273c";
            border-bottom-width: 0;

            a:last-child {
                &:hover,
                &:focus {
                    color: "#ffffff";
                }
            }
        `}
`;

export const StyledNavbarBody = styled.div`
    padding: 25px 0;
    ${device.large} {
        padding: 0;
        width: 100%;
    }
`;

export const StyledNavbarTitle = styled.h6`
    font-size: 10px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: 0.7px;
    color: "#8392a5";
    text-transform: uppercase;
    display: block;
    margin-bottom: 15px;
    padding-left: 20px;
    text-decoration: none;
    ${device.large} {
        display: none;
    }
`;

export const StyleNavbarRight = styled.div`
    padding: 0 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 2;
    ${device.xlarge} {
        width: 240px;
    }
    ${device.large} {
        order: 3;
        padding: 0 20px 0 0;
    }
`;

export const StyledNavbarElement = styled(({ mt, mb, ml, mr, ...props }) => (
    <div {...props} />
))`
    ${space};
    ${layout};

    /* Base icon styles */
    .theme-icon, .search-icon {
        width: 16px;
        height: 16px;
        stroke-width: 3px;
        color: #8392a5;
        transition: all 0.2s ease-in-out;
    }

    /* Light mode hover styles */
    &.theme-button button:hover .theme-icon {
        color: #0168fa;
    }

    &.search-button button:hover .search-icon {
        color: #0168fa;
    }

    /* Dark mode styles */
    body.dark-mode & {
        .theme-icon, .search-icon {
            color: #ffffff;
        }

        &.theme-button button:hover .theme-icon {
            color: #4a90e2;
        }

        &.search-button button:hover .search-icon {
            color: #4a90e2;
        }
    }

    /* Responsive styles */
    ${device.small} {
        .theme-icon, .search-icon {
            width: 20px;
            height: 20px;
            stroke-width: 2.25px;
        }
    }
`;

interface IMenuBtn {
    $hasSidebar?: boolean;
    $sidebarOpen?: boolean;
    $bodyOpen?: boolean;
}

export const StyledMenuBtn = styled(({ ...rest }) => (
    <Button {...rest} />
)) <IMenuBtn>`
    margin-left: 18px;
    svg {
        color: #8392a5;
    }
    ${({ $hasSidebar }) =>
        $hasSidebar === true &&
        css`
            display: none;
        `}
    ${({ $sidebarOpen, $bodyOpen }) =>
        $sidebarOpen === true &&
        !$bodyOpen &&
        css`
            display: block;
        `}
	${({ $bodyOpen, $sidebarOpen }) =>
        $bodyOpen === true &&
        $sidebarOpen === true &&
        css`
            display: none;
        `}
	${device.large} {
        display: none;
    }
    ${({ theme }) =>
        theme.name === "dark" &&
        css`
            &:hover {
                color: #ffffff;
            }
        `}
`;

export const StyledSidebarBtn = styled(({ ...rest }) => (
    <Button {...rest} />
)) <IMenuBtn>`
    margin-left: 18px;
    svg {
        color: #8392a5;
    }
    ${device.small} {
        margin-left: 20px;
    }
    ${device.large} {
        display: none;
    }
    ${({ $sidebarOpen, $bodyOpen }) =>
        $sidebarOpen === true &&
        !$bodyOpen &&
        css`
            display: none;
        `}
    ${({ $bodyOpen, $sidebarOpen }) =>
        $bodyOpen === true &&
        $sidebarOpen === true &&
        css`
            display: block;
        `}
    ${({ theme }) =>
        theme.name === "dark" &&
        css`
            &:hover {
                color: #ffffff;
            }
        `}
`;
